/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Partner() {
  return (
    <>
      <IndexNavbar fixed />
      

      
      <div data-aos="fade-in" className="page-title-area">
  <div className="d-table">
    <div className="d-table-cell">
      <div className="container">
        <div className="page-title-content">
          <h2 data-aos="zoom-out">Makan Partnership Program </h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section className=" pb-10 relative">
        {/* <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div> */}
        

        <div className="justify-center text-center flex flex-wrap mt-10">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <p data-aos="fade-up" className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            Makan ERP is an industry-focused, subscription-based ERP solution designed to enhance operational
efficiency for businesses across diverse sectors. The platform offers clients powerful ERP tools along with
options for customization, implementation and training to meet specific needs.
            </p>
            <p data-aos="fade-up" className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            As part of our global expansion strategy, Makan ERP is introducing a Partner Program that enables
selected partners to market, sell and customize Makan ERP independently. Through this partnership, we
aim to create a mutually beneficial opportunity that combines Makan ERP’s cutting-edge capabilities
with the partner’s local expertise and client relationships.

            </p>
          </div>
        </div>
      </section>
      



<section className="services-section pt-1 0 spt-50 spb-0 bg-blueGray-200">
            
<div className="container flex flex-col items-center justify-center min-h-screen">
  <div className="section-title text-center mb-8">
    <h2 className="font-semibold" data-aos="fade-up">
      Partner Program Benefits
    </h2>
    <span data-aos="fade-up" className="text-lg">
      Revenue Opportunities
    </span>
  </div>
  <div className="row flex flex-wrap justify-center">
    {/* First Column */}
    <div className="col-lg-4 col-md-6 mb-6" data-aos="zoom-out-up">
      <div className="single-services-box text-center">
        <div className="icon mb-4">
          <img src={require("assets/img/partner.jpg")} alt="Partner" />
        </div>
        <h3 className="text-xl font-semibold mb-2">Competitive Commissions</h3>
        <p>
          Partners receive a commission of 15-25% on each subscription sale,
          with increased rates available based on sales volume.
        </p>
      </div>
    </div>

    {/* Second Column */}
    <div className="col-lg-4 col-md-6 mb-6" data-aos="zoom-out-up">
      <div className="single-services-box text-center">
        <div className="icon mb-4">
          <img src={require("assets/img/partner.jpg")} alt="Partner" />
        </div>
        <h3 className="text-xl font-semibold mb-2">Customization Revenue</h3>
        <p>
          Partners can earn 10-15% on revenue from customization projects,
          which will be developed by Makan ERP.
        </p>
      </div>
    </div>
  </div>
</div>



      <div className="container">
        <div className="section-title">
          <span data-aos="fade-up">Access to Dedicated Developers
          </span>
        </div>
        <div className="row">
        <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
            <div className="single-services-box">
              <div className="icon">
              <img src={require("assets/img/partner.jpg")} />
               </div>
              <h3>Exclusive Developer Access</h3>
              <p>  Partners are provided with developers dedicated exclusively
to their projects, allowing partners to offer Makan ERP as a customizable solution for
their clients.</p>
              {/* <a className="service-btn" href="/service-details">Read More<i className="flaticon-right"></i></a> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
            <div className="single-services-box">
              <div className="icon">
              <img src={require("assets/img/partner.jpg")} />
              </div>
              <h3>Cost Savings</h3>
              <p> Monthly developer rates include the developer’s salary plus a service fee,
              creating a cost-effective alternative to local hiring.
</p>
              {/* <a className="service-btn" href="/service-details">Read More<i className="flaticon-right"></i></a> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
            <div className="single-services-box">
              <div className="icon">
              <img src={require("assets/img/partner.jpg")} />
              </div>
              <h3> Real-Time Monitoring</h3>
              <p>  Full transparency and productivity insights are available through
              remote task monitoring software, tracking all developer activities on partner projects.
</p>
              {/* <a className="service-btn" href="/service-details">Read More<i className="flaticon-right"></i></a> */}
            </div>
          </div>
        </div>


        <div className="container flex flex-col items-center justify-center min-h-screen ">
  <div className="section-title text-center mb-8">
    <span data-aos="fade-up" className="text-lg font-semibold">
      Source Code and Hosting Structure
    </span>
  </div>
  <div className="row flex flex-wrap justify-center">
    {/* First Column */}
    <div className="col-lg-4 col-md-6 mb-6" data-aos="zoom-out-up">
      <div className="single-services-box text-center">
        <div className="icon mb-4">
          <img src={require("assets/img/partner.jpg")} alt="Partner" />
        </div>
        <h3 className="text-xl font-semibold mb-2">
          Controlled Source Code Access
        </h3>
        <p>
          Only Makan ERP developers assigned to the partner’s projects have
          direct access to the source code, ensuring data protection.
        </p>
      </div>
    </div>

    {/* Second Column */}
    <div className="col-lg-4 col-md-6 mb-6" data-aos="zoom-out-up">
      <div className="single-services-box text-center">
        <div className="icon mb-4">
          <img src={require("assets/img/partner.jpg")} alt="Partner" />
        </div>
        <h3 className="text-xl font-semibold mb-2">NDA Compliance</h3>
        <p>
          All partners are required to sign a Non-Disclosure Agreement (NDA) to
          safeguard intellectual property and maintain data security.
        </p>
      </div>
    </div>
  </div>

      <div className="container flex flex-col items-center justify-center min-h-screen">
  <div className="section-title text-center mb-8">
    <span data-aos="fade-up" className="text-lg font-semibold">
      Support and Training
    </span>
  </div>
  <div className="row flex flex-wrap justify-center">
    {/* First Column */}
    <div className="col-lg-4 col-md-6 mb-6" data-aos="zoom-out-up">
      <div className="single-services-box text-center">
        <div className="icon mb-4">
          <img src={require("assets/img/partner.jpg")} alt="Partner" />
        </div>
        <h3 className="text-xl font-semibold mb-2">Comprehensive Onboarding</h3>
        <p>
          Initial training covers Makan ERP features, customization options,
          and effective sales strategies.
        </p>
      </div>
    </div>

    {/* Second Column */}
    <div className="col-lg-4 col-md-6 mb-6" data-aos="zoom-out-up">
      <div className="single-services-box text-center">
        <div className="icon mb-4">
          <img src={require("assets/img/partner.jpg")} alt="Partner" />
        </div>
        <h3 className="text-xl font-semibold mb-2">Ongoing Support</h3>
        <p>
          Partners have continuous access to Makan ERP’s technical team, with
          elevated support available for high-performing partners.
        </p>
      </div>
    </div>
  </div>


      <div className="container flex flex-col items-center justify-center min-h-screen">
  <div className="section-title text-center mb-8">
    <span data-aos="fade-up" className="text-lg font-semibold">
      Exclusive Growth Opportunities
    </span>
  </div>
  <div className="row flex flex-wrap justify-center">
    {/* First Column */}
    <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
      <div className="single-services-box text-center">
        <div className="icon mb-4">
          <img src={require("assets/img/partner.jpg")} alt="Partner" />
        </div>
        <h3 className="text-xl font-semibold mb-2">
          Potential for Regional Exclusivity
        </h3>
        <p>
          Partners who consistently achieve performance targets may be granted
          exclusivity in certain regions or industry sectors.
        </p>
      </div>
    </div>

    {/* Second Column */}
    <div className="col-lg-4 col-md-6" data-aos="zoom-out-up">
      <div className="single-services-box text-center">
        <div className="icon mb-4">
          <img src={require("assets/img/partner.jpg")} alt="Partner" />
        </div>
        <h3 className="text-xl font-semibold mb-2">Performance Targets</h3>
        <p>
          Partners are encouraged to meet annual targets, potentially unlocking
          additional benefits and revenue-sharing opportunities.
        </p>
      </div>
    </div>
  </div>
</div>


        

</div>
</div>
      </div>
    </section>
    <section className="services-section pt-1 0 spt-50 spb-0 ">
    <div className="section-title">
          
          <h2 className="font-semibold" data-aos="fade-up">  Program Structure
          </h2>
          <span data-aos="fade-up">Partner OnboardingRevenue Opportunities
          </span>
        </div>
        <div className="justify-center text-center flex flex-wrap mt-10">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <p data-aos="fade-up" className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            To ensure a seamless integration with the Makan ERP ecosystem, partners are required to make an Initial
Deposit based on their preferred partner level. This deposit secures access to Makan ERP resources,
training and exclusive program benefits.


            </p>
          </div>
        </div>
        <div className="container mx-auto">
  <div className="flex spt-10 flex-wrap items-stretch">
    <div
      data-aos="zoom-in-up"
      className="w-12/12 md:w-6/12 lg:w-5/12 px-12 md:px-4 mr-auto ml-auto -mt-10 flex"
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
        <img
          alt="..."
          src={require("assets/img/silver.png")}
          className="w-full align-middle rounded-t-lg"
        />
        <blockquote className="relative p-8 mb-4">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 583 95"
            className="absolute left-0 w-full block h-95-px -top-94-px"
          >
            <polygon
              points="-30,95 583,95 583,65"
              className="text-lightBlue-500 fill-current"
            ></polygon>
          </svg>
          <h4 className="text-xl font-bold text-white">Initial Deposit</h4>
          <p className="text-md font-light mt-2 text-white">$1,500 USD</p>
          <h4 className="text-xl font-bold text-white">Customization Access</h4>
          <p className="text-md font-light mt-2 text-white">
            Silver Partners can utilize Makan ERP’s in-house team for any required
            customization work, ensuring high-quality adaptations that align with our
            product standards.
          </p>
          <h4 className="text-xl font-bold text-white">Program Benefits</h4>
          <p className="text-md font-light mt-2 text-white">
            Access to standard commission rates, training resources, and baseline
            technical support.
          </p>
        </blockquote>
      </div>
    </div>

    <div
      data-aos="zoom-in-up"
      className="w-12/12 md:w-6/12 lg:w-5/12 px-12 md:px-4 mr-auto ml-auto -mt-10 flex"
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
        <img
          alt="..."
          src={require("assets/img/Gold.png")}
          className="w-full align-middle rounded-t-lg"
        />
        <blockquote className="relative p-8 mb-4">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 583 95"
            className="absolute left-0 w-full block h-95-px -top-94-px"
          >
            <polygon
              points="-30,95 583,95 583,65"
              className="text-lightBlue-500 fill-current"
            ></polygon>
          </svg>
          
          <h4 className="text-xl font-bold text-white">Initial Deposit</h4>
          <p className="text-md font-light mt-2 text-white">$2,500 USD</p>
          <h4 className="text-xl font-bold text-white">Customization Access</h4>
          <p className="text-md font-light mt-2 text-white">
            Gold Partners are provided with additional flexibility, including the
            option to hire dedicated developers directly through Makan ERP for ongoing
            or high-demand customization projects. These developers will work
            exclusively on the partner’s projects and be accessible for oversight and
            guidance.
          </p>
          <h4 className="text-xl font-bold text-white">Program Benefits</h4>
          <p className="text-md font-light mt-2 text-white">
            Higher commission potential, elevated technical support, and priority access
            to senior Makan ERP resources for enhanced customization and project
            management.
          </p>
        </blockquote>
      </div>
    </div>
  </div>

  
<div className="flex flex-wrap justify-between items-start mt-10">
  {/* Left Side */}
  <div className="w-full md:w-6/12 lg:w-6/12 text-left px-4">
    {/* <h3 className="text-2xl font-bold">Revenue Model Overview</h3> */}
    <div className="section-title">
  <span data-aos="fade-up">Revenue Model</span>
</div>
<ul className="list-disc list-inside space-y-4">
      <li>
        <h4 className="text-xl font-bold">Subscription Sales:</h4>
        <p>Partners receive a 15-25% commission on each subscription sale.</p>
      </li>
      <li>
        <h4 className="text-xl font-bold">Customization Fees:</h4>
        <p>Partners earn 10-15% of the revenue from customization projects.</p>
      </li>
      <li>
        <h4 className="text-xl font-bold">Dedicated Developer Fees:</h4>
        <p>
          Monthly charges for each assigned developer cover both salary and a service 
          fee (25% of the salary).
        </p>
      </li>
    </ul>
  </div>

  {/* Right Side */}
  <div className="w-full md:w-6/12 lg:w-6/12 px-4">
  <div className="section-title">
  <span data-aos="fade-up">Monitoring and Compliance</span>
</div>
    <ul className="list-disc list-inside space-y-4">
      <li>
        <p>Partners can monitor dedicated developers in real time using Desklog, ensuring full
        visibility into productivity and project focus.</p>
      </li>
      <li>
        <p>Adherence to Makan ERP’s terms and conditions is required, including NDA compliance,
source code access limitations, and performance review processes.
</p>
      </li>
    </ul>
  </div>
</div>
<div className="flex flex-wrap justify-between items-start mt-10">
  {/* Left Side */}
  <div className="w-full md:w-6/12 lg:w-6/12 text-left px-4">
    {/* <h3 className="text-2xl font-bold">Revenue Model Overview</h3> */}
    <div className="section-title">
  <span data-aos="fade-up"> Termination and Exit Clauses</span>
</div>
<ul className="list-disc list-inside space-y-4">
      <li>
        <p>Makan ERP reserves the right to end the partnership if performance targets are unmet
        or compliance issues arise.</p>
      </li>
    </ul>
  </div>

  {/* Right Side */}
  <div className="w-full md:w-6/12 lg:w-6/12 px-4">
  <div className="section-title">
  <span data-aos="fade-up">Refund Upon Termination</span>
</div>
    <ul className="list-disc list-inside space-y-4">
      <li>
      <h4 className="text-xl font-bold"> Voluntary Termination by Partner:</h4>
        <p> Refunds are capped at 50% of the initial deposit if
        performance targets were unmet.</p>
      </li>
      <li>
      <h4 className="text-xl font-bold">Termination by Makan ERP</h4>
        <p> Due to compliance or performance issues:
</p>
<ol className="unorder" style={{ listStyleType: "decimal" }}>
  <li>No refund if termination occurs due to non-compliance with NDA or source code misuse</li>
  <li>Refund up to 30% of the initial deposit if the partner maintained acceptable
    performance but did not meet growth targets over two consecutive years.
  </li>
</ol>


      </li>
    </ul>
  </div>
</div>



<div className="container mx-auto relative">
  <div className="section-title">
    <h2 className="font-semibold" data-aos="fade-up">Next Steps</h2>
  </div>

  {/* Main Section with Steps */}
  <div className="flex flex-wrap justify-between items-center gap-8 bg-white shadow-xl rounded-lg mt-10 py-16 px-12 relative z-10">
    <div data-aos="zoom-out-down" className="relative flex flex-col items-center w-full md:w-4/12">
      <div className="icon mb-5">
        <img src={require("assets/img/proposal.png")} alt="Proposal Review" className="w-16 h-16 object-contain" />
      </div>
      <h6 className="text-xl mb-1 font-semibold">Proposal Review</h6>
      <p className="text-center">Prospective partners to review and provide feedback on the proposal.</p>
    </div>

    <div data-aos="zoom-out-down" className="relative flex flex-col items-center w-full md:w-4/12">
      <div className="icon mb-5">
        <img src={require("assets/img/agreement.png")} alt="Agreement and Onboarding" className="w-16 h-16 object-contain" />
      </div>
      <h6 className="text-xl mb-1 font-semibold">Agreement and Onboarding</h6>
      <p className="text-center">Once terms are agreed upon, both parties will sign a formal Partner Agreement, including the NDA and terms and conditions.</p>
    </div>

    <div data-aos="zoom-out-down" className="relative flex flex-col items-center w-full md:w-4/12">
      <div className="icon mb-5">
        <img src={require("assets/img/partinership.png")} alt="Initiation of Partnership" className="w-16 h-16 object-contain" />
      </div>
      <h6 className="text-xl mb-1 font-semibold">Initiation of Partnership</h6>
      <p className="text-center">Makan ERP will provide partner onboarding, training and developer allocation as specified in the program structure.</p>
    </div>
    {/* Contact Us Button - Ensures Proper Positioning */}
  <div className="flex flex-col items-center justify-center mt-16 mb-16 relative flex flex-col items-center w-full md:w-12/12">
    <Link
      data-aos="zoom-in"
      to="./contact"
      className="github-star text-white font-bold px-6 py-4 rounded outline-none focus:outline-none bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg"
    >
      Contact With Us
    </Link>
  </div>
  </div>

  
</div>
</div>

      </section>

      <Footer />
    </>
  );
}
