/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function CRMS() {
  return (
    <>
      <IndexNavbar fixed />
  
      <div data-aos="fade-in" class="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">VEHICLE MAINTENANCE SYSTEM</h2>
              </div>
              </div>
            </div>
          </div>
        </div>
      <section className=" md:mt-10 pb-20 relative bg-blueGray-100">
       
        <div className="container mx-auto px-4 pb-20 pt-20">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-10/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <h3 data-aos="fade-up" className="text-3xl font-semibold text-center">
                Vehicle Maintenance System
                </h3>
                <div data-aos="zoom-out-up" className="md:w-8/12 m-auto pt-20">
                <img data-aos="zoom-out-up"
                alt="..."
                className="max-w-full"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={require("assets/img/vehicle_maintenance.png")}
              />
                </div>
              </div>
            </div>

         
          </div>
        </div>
      </section>
      
        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">     
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Master</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Add Divisions
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Add Expense Category
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Driver
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Add Vehicle
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Tyre
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
     
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Maintenance</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Tyre & Maintenance
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          General Maintenance
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Vehicle Status
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Settings</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          User Management
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          User Profiles
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Reminders
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Company Details
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Module Manager
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
               
            </div>
            
          </div>
        </section>

        <section className="relative pb-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">     
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Reports</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Tyre & Maintenance Report
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          General Maintenance Report
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Vehicle Status Report
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Current Tyre Details Report
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> 
            </div>
            
          </div>
        </section>
       

      <section className="bg-blueGray-200 relative pt-20">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

      </section>
      <Footer />
    </>
  );
}
