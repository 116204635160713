/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
  
      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">Services</h2>
              </div>
              </div>
            </div>
          </div>
        </div>
      <section className=" md:mt-10 pb-20 relative bg-blueGray-100"> 
        <div className="container mx-auto px-4 pb-32 pt-48">
          <div className="items-center flex flex-wrap">
          <div data-aos="zoom-out-up" className="w-full md:w-6/12 ml-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full"
             
                src={require("assets/img/erp-software.png")}
              />
            </div>

            <div className="w-full md:w-6/12 mr-auto px-12 md:px-4">
              <div>
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                ERP Solutions
                </h3>
                <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                At Greeniit, we provide end-to-end application development solutions starting from initial business case analysis to post-implementation support. We leverage the best of technologies and practices to ensure high quality business applications.</p>
              <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">Our Custom Applications are tailored to meet your requirements. We provide Online/Web based and Desktop applications, that help your organization and your clients to effectively manage projects, collaborate and share information. Our specialists work with you throughout the life cycle of the project to determine best practices and requirements, ensuring rapid development, testing and implementation.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-blueGray-100"> 
        <div className="container mx-auto px-4 pb-32 ">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 mr-auto px-12 md:px-4">
              <div>
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                Custom Software Development
                </h3>
                <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                At Greeniit, we provide end-to-end application development solutions starting from initial business case analysis to post-implementation support. We leverage the best of technologies and practices to ensure high quality business applications.</p>
              <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">Our Custom Applications are tailored to meet your requirements. We provide Online/Web based and Desktop applications, that help your organization and your clients to effectively manage projects, collaborate and share information. Our specialists work with you throughout the life cycle of the project to determine best practices and requirements, ensuring rapid development, testing and implementation.</p>
              </div>
            </div>
            <div data-aos="zoom-out-up" className="w-full md:w-6/12 ml-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full"
             
                src={require("assets/img/custom.png")}
              />
            </div>
          </div>
        </div>
      </section>
      <section className=" md:mt-10 pb-20 relative bg-blueGray-100"> 
        <div className="container mx-auto px-4 pb-32 pt-48">
          <div className="items-center flex flex-wrap">
          <div data-aos="zoom-out-up" className="w-full md:w-6/12 ml-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full"
             
                src={require("assets/img/web_application.png")}
              />
            </div>

            <div className="w-full md:w-6/12 mr-auto px-12 md:px-4">
              <div>
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                Web Application
                </h3>
                <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Greeniit deals with web application development projects of any density and duration. Be it a small web site, a diversified e-commerce web application or corporate website, the experienced developers and skilled programmers at Greeniit work in close association with the clients. Greeniit offers hi-end website development and professional web applications for your business requirements. A creative team of best professionally educated designers, skilled and experienced programmers and highly professional managers are always at your disposal on your development project.</p>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-blueGray-100"> 
        <div className="container mx-auto px-4 pb-32 ">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 mr-auto px-12 md:px-4">
              <div>
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                Mobile Application
                </h3>
                <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                We create outstanding mobile apps to allow our users to exactly decipher the ideations of innovation in order to make their enterprises truly distinctive. Experience a dignified quality in the custom mobile app development and grapple an seamless and robust mobility ecosystem with highly interactive displays and reaffirmed quality. Development team at Greeniit build scalable and engaging mobile apps as per your business requirements that are highly sophisticated and extremely functional. We adopt multiple operating system, app development platforms, cross platforms and devices for developing robust and scalable mobile apps with clean displays that are truly astonishing. Working with iPhone Application Development, Google Android Application Development and Multi Platform App Development​.​ So, grab an enriched enterprise mobility solution with our rapid mobile app development that strictly adheres to your idea.</p>
               </div>
            </div>
            <div data-aos="zoom-out-up" className="w-full md:w-6/12 ml-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full"
             
                src={require("assets/img/documentation.png")}
              />
            </div>
          </div>
        </div>
      </section>
      <section className=" md:mt-10 pb-20 relative bg-blueGray-100"> 
        <div className="container mx-auto px-4 pb-32 pt-48">
          <div className="items-center flex flex-wrap">
          <div data-aos="zoom-out-up" className="w-full md:w-6/12 ml-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full"
             
                src={require("assets/img/web_design.png")}
              />
            </div>

            <div className="w-full md:w-6/12 mr-auto px-12 md:px-4">
              <div>
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                Web Design
                </h3>
                <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Website is no longer an option these days for any kind of business. It is very much an indispensable part of your business. Your business is incomplete without a website. Whether your website is the primary source of your income or just a medium to inform others about your company, it is just as much required. A website can be designed for any purpose at all. Even individuals are getting creative with their personal websites to claim their own space online. Many individuals are designing their websites to tell others about themselves. A personal website can also be gifted to someone you like. It is cool to have a website, then why not?</p>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-blueGray-100"> 
        <div className="container mx-auto px-4 pb-32 ">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 mr-auto px-12 md:px-4">
              <div>
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                Graphic Design
                </h3>
                <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                We provid solutions for illustration, web and graphic design to individuals, businesses, and organizations worldwide. From creating the perfect corporate identity to designing a customized web site, our experienced team of design experts possess the creativity and knowledge to handle all of your design needs.</p>
               </div>
            </div>
            <div data-aos="zoom-out-up" className="w-full md:w-6/12 ml-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full"
             
                src={require("assets/img/graphic_design.png")}
              />
            </div>
          </div>
        </div>
      </section>
      <section className=" md:mt-10 pb-20 relative bg-blueGray-100"> 
        <div className="container mx-auto px-4 pb-32 pt-48">
          <div className="items-center flex flex-wrap">
          <div data-aos="zoom-out-up" className="w-full md:w-6/12 ml-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full"
             
                src={require("assets/img/corporate.png")}
              />
            </div>

            <div className="w-full md:w-6/12 mr-auto px-12 md:px-4">
              <div>
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                Corporate Identity
                </h3>
                <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                It’s for you…In tune with your aspirations. Listen to the rhythm escalations In Designing, Branding, Film Making, Strategy Building, Consultationand establishment of your corporate identity. Some appealing distances are there…When you step forward, it blooms a rainbow for you. Greeniit…the point where your seek ends.</p>

              </div>
            </div>
          </div>
        </div>
      </section>
 
  

      <section className="bg-blueGray-200 relative pt-20">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

      </section>
      <Footer />
    </>
  );
}
