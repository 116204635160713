/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function CRMS() {
  return (
    <>
      <IndexNavbar fixed />
  
      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">ONLINE POS</h2>
              </div>
              </div>
            </div>
          </div>
        </div>
        <section className=" md:mt-10 pb-20 relative bg-blueGray-100">
       
       <div className="container mx-auto px-4 pb-20 pt-20">
         <div className="items-center flex flex-wrap">
           <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
             <div className="md:pr-12">
               <h3 data-aos="fade-up" className="text-3xl font-semibold">
               Online POS (Point Of Sales)
               </h3>
               <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
               A point of sale system is a combination that allows merchants to take transactions and simplify key day-to-day business operations. Modern POS systems do more than just offer flexibility when processing daily transactions. They improve a merchant’s chances of success by providing them with tools to streamline business processes.</p>
              
             </div>
           </div>

           <div data-aos="zoom-out-up" className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
             <img
               alt="..."
               className="max-w-full"
               style={{
                 transform:
                   "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
               }}
               src={require("assets/img/pos.png")}
             />
           </div>
         </div>
       </div>
     </section>
      
        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">     
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Dashboard</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Minimum Quantity Alert
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Short Cuts to Sales, Purchase, Payments and stock
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
     
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Stock</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Edit Stock
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Stock Detailed Report

                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Purchase</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Purchase Items
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Purchase return
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Vendor Payment
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Vendor wise Payment Report
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>  
            </div>
            
          </div>
        </section>

        <section className="relative pb-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">     
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Sales</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Counter Sales
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Sales return
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Re-Bill
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Customer Payment
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Customer wise Payment Report
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Master</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Category
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Type
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Unit
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Expense
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Customer Details
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Vendor Details
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Products Details
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Expense Details
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Accounting</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Transactions
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Reports
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          A/C Masters
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Main Accounts
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Fiscal Year
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Sub A/c Settings
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Main Account Setting
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Reports
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>   
            </div>
            
          </div>
        </section>

        <section className="relative pb-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">     
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Reports</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Complete reports
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Profit and loss report of each invoice
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Profit and loss report
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Settings</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Application Settings
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          User Management
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Database Backup and Restore Option
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
  
            </div>
            
          </div>
          
        </section>
       

      <section className="bg-blueGray-200 relative pt-20">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

      </section>
      <Footer />
    </>
  );
}
