/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function School_management_system() {
  return (
    <>
      <IndexNavbar fixed />
  
      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">School Management System</h2>
              </div>
              </div>
            </div>
          </div>
        </div>
      <section className=" md:mt-10 pb-20 relative bg-blueGray-100">
       
        <div className="container mx-auto px-4 pb-20 pt-20">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                School Management System
                </h3>
                <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Our comprehensive school management and administration software package, add quality of academic institutions by letting efficient monitoring of the day to day progress and performance. What made us the best is our features that allows to stay updated with the performance levels.</p>
               
              </div>
            </div>

            <div data-aos="zoom-out-up" className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={require("assets/img/school-erp.png")}
              />
            </div>
          </div>
        </div>
      </section>
      
        
      <Footer />
    </>
  );
}
