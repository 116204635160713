/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";


import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import img1 from '../assets/img/about_us.png';

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
  
      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">About Us</h2>
              </div>
              </div>
            </div>
          </div>
        </div>
      <section className=" md:mt-10 pb-20 relative bg-blueGray-100">
       
        <div className="container mx-auto px-4 pb-32 pt-48">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                Welcome to Greeniit
                </h3>
                <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                We keep our product lines updated with the latest innovations in the respective segments. Our end-to-end service and support across all solutions we deliver is an assurance of our total commitment to attend to your immediate IT needs. Greeniit offers your business the opportunity to take advantage of our products to drive your business momentum with a strong IT environment </p>
              </div>
            </div>

            <div data-aos="zoom-out-up" className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={img1}
              />
            </div>
          </div>
        </div>

        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <h2 data-aos="fade-up" className="font-semibold text-4xl">Who We Are</h2>
            <p data-aos="fade-up" className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            Break down the barriers to innovation with new and enhanced solutions from Greeniit. Design, develop and deliver innovative software, systems and solutions that drive more predictable outcomes and increase productivity.
            </p>
            <p data-aos="fade-up" className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            Greeniit Products are designed with technology expertise gained globally and crafted using world class engineering infrastructure. With products that are a hallmark of quality, we take pride in helping you build a responsible computing environment. Count on us to be there with you as a partner all through the lifecycle, with our enthusiam and urge to grow. You don't buy just our products; you invest in a value that is perennial: Trust.</p>
            <p data-aos="fade-up" className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            Expanding businesses are on the constant search for the latest IT products that can help them come up with a comprehensive solution to drive home the competitive advantage. Asset productivity through implementation of integrated technology solutions help suit your domain specific needs. In the business of addressing enterprises, Greeniit partners with limit of breed alliances to refurbish thier IT infrastructure and extends a unique opportunity to leverage the latest technology products and services at competitive costs.</p>
          </div>
        </div>
      </section>

      <section className="process-section pt-100 pb-70  pb-40 relative bg-blueGray-100">
        <div className="container">
          <div className="section-title">
            <span data-aos="fade-up">OUR PROCESS</span>
            <h2 data-aos="fade-up" className="font-semibold">Designing development and implementation</h2></div>
            <div className="row">
              <div data-aos="fade-zoom-in"  data-aos-delay="200"  className="col-lg-4 col-md-6">
                <div className="process-item">
                <img alt="..." className="align-middle border-none max-w-full h-auto rounded-lg" src={require("assets/img/process1.png")} />
                    <h3>Planning</h3>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p> */}
                </div>
              </div>
              <div data-aos="fade-zoom-in"  data-aos-delay="600" className="col-lg-4 col-md-6">
                <div className="process-item">
                <img alt="..." className="align-middle border-none max-w-full h-auto rounded-lg" src={require("assets/img/process2.png")} />
                  <h3>Design &amp; Execution</h3>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p> */}
                </div>
              </div>
              <div data-aos="fade-zoom-in"  data-aos-delay="1000" className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div className="process-item">
                <img alt="..." className="align-middle border-none max-w-full h-auto rounded-lg" src={require("assets/img/process3.png")} />
                  <h3>Development & Testing</h3>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
 
  

      <section className="bg-blueGray-200 relative pt-20">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

      </section>
      <Footer />
    </>
  );
}
