/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function CRMS() {
  return (
    <>
      <IndexNavbar fixed />
  
      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">CRMS</h2>
              </div>
              </div>
            </div>
          </div>
        </div>
      <section className=" md:mt-10 pb-20 relative bg-blueGray-100">
       
        <div className="container mx-auto px-4 pb-20 pt-20">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                Customer Relationship Management Solution
                </h3>
                <p data-aos="fade-up"  className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Greeniit Customer Resource Management Solution aims at helping you build a profitable and sustainable business based on your customer relationships. In short we want to help you get new customers and keeping the ones you have. From pre-sales till after sales Customer support we provide a complete end to end package aimed at automating your company's complete sales cycle. Sales Force Automation, Marketing Automation, Customer Support, Order and Inventory Management are some of the features of this exciting solution that guarantees that your customer will never go elsewhere.</p>
               
              </div>
            </div>

            <div data-aos="zoom-out-up" className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={require("assets/img/crm.png")}
              />
            </div>
          </div>
        </div>
      </section>
      
        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">     
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">Master & Home</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Dashboard
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Lead Source
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Document
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Lead Status
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Lead Type
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Task Subject
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Task Status
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Priority
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Category
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Nationality
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
     
              <ul className="list-none mt-6">                 
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Terms And Condition

                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Agent/Agency
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Deal Status
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Cost Margin
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Revenue Category
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Industry
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Mail Template
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Cost Workout Category
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Deal Category
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Account Details
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              
            </div>
            
          </div>
        </section>

        <section className="relative pb-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap"> 
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">CRM Management</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Contact
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Lead Details
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Task Details
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Deal Details
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Quotation
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Invoice
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Payment
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>      
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 data-aos="flip-down" className="text-3xl font-semibold">CRM Reports</h3>
                  <ul className="list-none mt-6">
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Deal Report
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Lead Report
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Contact Report
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          CRM Flow Report
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          CRM Report
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Quotation Report
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Invoice Report
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li data-aos="zoom-out" className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i class="fas fa-arrow-right"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                          Membership & Renewal Report
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
   
            </div>
            
          </div>
        </section>

      <section className="bg-blueGray-200 relative pt-20">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

      </section>
      <Footer />
    </>
  );
}
