/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Partner() {
  return (
    <div className="flex flex-col min-h-screen">
      <IndexNavbar fixed />

      {/* Main Content */}
      <div className="flex-grow">
        {/* Page Title */}
        <div data-aos="fade-in" className="page-title-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2 data-aos="zoom-out">Makan Partners</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Partner Section */}
        <section className="block relative z-1 pb-32 bg-blueGray-600 flex items-center justify-center min-h-screen mt-16">
          <div className="w-full">
            <div className="section-title text-center mb-8">
              <h2 className="font-semibold" data-aos="fade-up" style={{ color: "white" }}>
                DUBAI
              </h2>
            </div>

            <div className="container mx-auto">
              <div className="justify-center flex flex-wrap">
                <div className="w-full spt-10 lg:w-12/12 px-4 -mt-5">
                  <div className="flex flex-wrap justify-center">
                    {/* MSN Partner */}
                    <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                      <h5 className="c-green text-xl font-semibold pb-4 text-center">
                        MSN
                      </h5>
                      {/* <a target="_blank" href={require("assets/img/works/msn.png")}> */}
                        <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                          <img
                            alt="..."
                            className="align-middle border-none max-w-full h-auto rounded-lg"
                            src={require("assets/img/works/msn.png")}
                          />
                        </div>
                      {/* </a> */}
                    </div>

                    {/* DATAWARE Partner */}
                    <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                      <h5 className="c-green text-xl font-semibold pb-4 text-center">
                        DATAWARE
                      </h5>
                      {/* <a target="_blank" href={require("assets/img/works/dataware.png")}> */}
                        <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                          <img
                            alt="..."
                            className="align-middle border-none max-w-full h-auto rounded-lg"
                            src={require("assets/img/works/dataware.png")}
                          />
                        </div>
                      {/* </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Qatar Section */}
        <section className="block relative z-1 pb-32 bg-blueGray-600 flex items-center justify-center min-h-screen ">
          <div className="w-full">
            <div className="section-title text-center mb-4">
              <h2 className="font-semibold" data-aos="fade-up" style={{ color: "white" }}>
                QATAR
              </h2>
            </div>

            <div className="container mx-auto">
              <div className="justify-center flex flex-wrap">
                <div className="w-full spt-10 lg:w-12/12 px-4 -mt-5">
                  <div className="flex flex-wrap justify-center">
                    {/* NEUHEIT Partner */}
                    <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                      <h5 className="c-green text-xl font-semibold pb-4 text-center">
                        NEUHEIT
                      </h5>
                      {/* <a target="_blank" href={require("assets/img/works/neuheit.jpg")}> */}
                        <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                          <img
                            alt="..."
                            className="align-middle border-none max-w-full h-auto rounded-lg"
                            src={require("assets/img/works/neuheit.jpg")}
                          />
                        </div>
                      {/* </a> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Call-to-Action Link */}
              <div className="flex justify-center mt-16 mb-32">
  <Link
    to="/partner"
    className="inline-block text-white font-semibold px-4 py-4 rounded-lg shadow-md border border-gray-300 hover:bg-opacity-80 transition duration-300 ease-in-out"
    style={{
      background: "linear-gradient(to right, #25a5c4 0%, #b5d560 100%)", // Gradient background
      animation: "moveUpDown 2s ease-in-out infinite", // Added animation
    }}
  >
    Empower your business with MAKAN ERP Partnership Program. 
    <span className="text-blue-500 font-bold" style={{color:"green"}}> Click here to know more...</span>

  </Link>
</div>


<style>
  {`
    @keyframes moveUpDown {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px); /* Move up */
      }
      100% {
        transform: translateY(0); /* Back to original position */
      }
    }
  `}
</style>


            </div>
          </div>
        </section>
      </div>

      {/* Footer (always stays at the bottom) */}
      <Footer />
    </div>
  );
}


