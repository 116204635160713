/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from 'components/ScrollToTop';
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
    <ScrollToTop />
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg bg-white shadow">
      
    <div class="container-fluid top_header_wrapper">
      <div class="row">
      <div class="container ">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="top_header_add">
                        {/* <ul>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i>Muscat, Oman</li>
                            <li><i class="fa fa-phone" aria-hidden="true"></i>+968-91382696</li>
                            <li><i class="fa fa-envelope" aria-hidden="true"></i><a href="#">info@greeniitc.com</a></li>
                        </ul> */}
                        {/* <ul>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i> Qatar</li>
                            <li><i class="fa fa-phone" aria-hidden="true"></i>+974-55597007</li>
                            <li><i class="fa fa-envelope" aria-hidden="true"></i><a href="#">support@neuheitsolutions.com</a></li>
                        </ul> */}
                    </div>
                     <div class="top_login opacity-0">
                        <ul>
                          <li className="flex items-center">
                            <a
                              className="hover:text-blueGray-500  px-3 py-4 lg:py-2 flex items-center text-xs p-r-0 uppercase"
                              href="#"
                              target="_blank"
                            ><i class="fa-solid fa-right-to-bracket r-p-10"></i>
                              &nbsp; <b>Customer Login</b>
                            </a>
                            <a
                              className="hover:text-blueGray-500  px-3 py-4 lg:py-2 flex items-center text-xs p-r-0 uppercase"
                              href="#"
                              target="_blank"
                            ><i class="fa-solid fa-right-to-bracket r-p-10"></i>
                              &nbsp; <b>PMS Login</b>
                            </a>
                          </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>    
    </div>
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between h-65 lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4  whitespace-nowrap uppercase"
            >
             <img
                  alt="..."
                  src={require("assets/img/greeniit_logo.svg").default}
                  className="py-2 max-h-65 max-w-110-px"
                />
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >

            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto top-navbar">
            <li className="flex items-center">
              <Link
                to="/about"
                className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase">
                About
              </Link>
              </li>
              <li className="flex items-center">
              <Link
                to="/services"
                className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase">
                Services
              </Link>
              </li>
              <li className="flex items-center">
                <IndexDropdown />
              </li>
              <li className="flex items-center">
              <Link
                to="/portfolio"
                className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase">
                Portfolio
              </Link>
              </li>
              {/* <li className="flex items-center">
              <Link
                to="/makanpartners"
                className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase">
                Makan Partners
              </Link>
              </li> */}
              <li className="flex items-center">
              <Link
                to="/contact"
                className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase">
                Contact
              </Link>
              </li>
          

              {/* <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase"
                  href="tel:+96891382696"
                  target="_blank"
                >
                  <i className="text-lightBlue-400 fa fa-phone text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Call</span>
                </a>
              </li> */}

              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-whatsapp-green  px-3 py-4 lg:py-2 flex items-center text-xs uppercase"
                  href="https://wa.me/+96891382696"
                  target="_blank"
                >
                  <i className="text-whatsapp-green  fab fa-whatsapp text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Whatsapp</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-pink-400 px-3 py-4 lg:py-2 flex items-center text-xs uppercase"
                  href="https://www.instagram.com/greeniitc/"
                  target="_blank"
                >
                  <i className="text-pink-400 fab fa-instagram text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
