import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, HashRouter } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";
import "./assets/styles/home.css";
import "./assets/styles/raxr.css";


// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import About from "views/About.js";
import Services from "views/Services.js";
import Portfolio from "views/Portfolio.js";
import HRMS from "views/HRMS.js";
import CRMS from "views/CRMS.js";
import School_management_system from "views/School_management_system.js";
import Property from "views/Property.js";
import Consignment from "views/Consignment.js";
import PMS from "views/PMS.js";
import Clinic from "views/Clinic.js";
import Inventory from "views/Inventory.js";
import POS from "views/POS.js";
import Vehicle_maintenance from "views/Vehicle_maintenance.js";
import Billing from "views/Billing.js";
import Time from "views/Time.js";
import Cheque from "views/Cheque.js";
import Production from "views/Production.js";
import Rbbill from "views/Rbbill.js";
import Contact from "views/Contact.js";
import Hotel_management from "views/Hotel_management.js";
import Courier from "views/Courier.js";
import Accounting from "views/Accounting.js";
import Support_Management_System from "views/Support_Management_System.js";
import Index from "views/Index.js";
import ScrollToTop from "views/ScrollToTop.js";
import Particle from "views/Particle.js";
import Partner from "views/Partner.js";
import Makanpartners from "views/Makanpartners.js";


ReactDOM.render(
  
  <BrowserRouter>
  <ScrollToTop />
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      {/* add routes without layouts */}
      <Route path="/landing" exact component={Landing} />
      <Route path="/about" exact component={About} />
      <Route path="/services" exact component={Services} />
      <Route path="/portfolio" exact component={Portfolio} />
      <Route path="/hrms" exact component={HRMS} />
      <Route path="/crms" exact component={CRMS} />
      <Route path="/school_management_system" exact component={School_management_system} />
      <Route path="/property" exact component={Property} />
      <Route path="/consignment" exact component={Consignment} />
      <Route path="/pms" exact component={PMS} />
      <Route path="/clinic" exact component={Clinic} />
      <Route path="/inventory" exact component={Inventory} />
      <Route path="/pos" exact component={POS} />
      <Route path="/vehicle_maintenance" exact component={Vehicle_maintenance} />
      <Route path="/billing" exact component={Billing} />
      <Route path="/time" exact component={Time} />
      <Route path="/cheque" exact component={Cheque} />
      <Route path="/production" exact component={Production} />
      <Route path="/rbbill" exact component={Rbbill} />
      <Route path="/hotel_management" exact component={Hotel_management} />
      <Route path="/courier" exact component={Courier} />
      <Route path="/accounting" exact component={Accounting} />
      <Route path="/Support_Management_System" exact component={Support_Management_System} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/" exact component={Index} />
      <Route path="/partner" exact component={Partner} />
      <Route path="/makanpartners" exact component={Makanpartners} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);


